/* eslint-disable */
import * as Types from '../types.gen'

import { gql } from '@apollo/client/index.js'
import { PromoterDetailsFragmentDoc } from './promoterDetails.fragment.gen'
export type EventDetailsFragment = {
  __typename?: 'Event'
  id: string
  published?: boolean | null
  slug: string
  name?: string | null
  description?: string | null
  picture?: string | null
  startTime: string
  isSaved?: boolean | null
  venueAccepted?: boolean | null
  artistIdsAccepted: Array<string>
  promoterAccepted?: boolean | null
  allParticipantsAccepted?: boolean | null
  products: Array<{
    __typename?: 'Product'
    id: string
    name: string
    quantity: number
    price: { __typename?: 'Price'; amount: number }
  }>
  venue?: {
    __typename?: 'Venue'
    id: string
    name: string
    slug: string
    picture?: string | null
    ageRestriction?: Types.AgeRestriction | null
    city: { __typename?: 'City'; id: number; name: string }
  } | null
  genres: Array<{ __typename?: 'Genre'; id: number; name: string } | null>
  artists: Array<{
    __typename?: 'Artist'
    id: string
    name: string
    slug: string
    picture?: string | null
    genres: Array<{ __typename?: 'Genre'; id: number; name: string }>
  }>
  promoter?: {
    __typename: 'Promoter'
    id: string
    name: string
    description: string
    picture?: string | null
    slug: string
    links: Array<{ __typename?: 'Link'; name: string; url: string }>
  } | null
}

export const EventDetailsFragmentDoc = gql`
  fragment EventDetails on Event {
    id
    published
    slug
    name
    description
    picture
    startTime
    products {
      id
      name
      price {
        amount
      }
      quantity
    }
    isSaved
    venue {
      id
      name
      slug
      picture
      ageRestriction
      city {
        id
        name
      }
    }
    venueAccepted
    genres {
      id
      name
    }
    artists {
      id
      name
      slug
      picture
      genres {
        id
        name
      }
    }
    artistIdsAccepted
    promoter {
      ...PromoterDetails
    }
    promoterAccepted
    allParticipantsAccepted
  }
  ${PromoterDetailsFragmentDoc}
`
